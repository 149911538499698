// TODO: edit the color of the line chart

const lineColor = {
    "temperature": "#FF0000",
    "humidity": "#0000FF",
    "soilMoisture": "#00FF00",
    "light": "#FFFF00",
    "pH": "#FF00FF",
    "nitrogen": "#00FFFF",
    "phosphorous": "#FFA500",
    "potassium": "#800080",
    "conductivity": "#008000",

}

export default lineColor;