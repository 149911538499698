import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Button } from '@mui/material';


const LoadingDialog = ({ open, onClose }) => {
    // TODO: css this
    const dialogStyles = {
      paper: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '400px',
        width: '100%',
      },
      title: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#333',
      },
      content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
      },
      actions: {
        display: 'flex',
        justifyContent: 'center',
        padding: '10px',
      },
      closeButton: {
        backgroundColor: '#3498db',
        color: '#fff',
        border: 'none',
        padding: '8px 16px',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      closeButtonHover: {
        backgroundColor: '#2980b9',
      },
      circularProgress: {
        color: '#3498db',
      },
    };
  
    return (
      <Dialog open={open} onClose={onClose} PaperProps={{ style: dialogStyles.paper }}>
        <DialogTitle style={dialogStyles.title}>Loading...</DialogTitle>
        <DialogContent style={dialogStyles.content}>
          <CircularProgress style={dialogStyles.circularProgress} />
        </DialogContent>
        <DialogActions style={dialogStyles.actions}>
          <Button 
            onClick={onClose} 
            style={dialogStyles.closeButton}
            onMouseEnter={e => e.target.style.backgroundColor = dialogStyles.closeButtonHover.backgroundColor}
            onMouseLeave={e => e.target.style.backgroundColor = dialogStyles.closeButton.backgroundColor}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default LoadingDialog;