import React from 'react';
import { Button, Dialog, Box, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const NotificationDialog = ({open, setOpen, message, dialogType}) => {

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: '24px'}} >{ dialogType === 'success' ? 'Success' : 'Error'}</DialogTitle>
        <Box p="1px"></Box>
        <DialogContent>
          <DialogContentText sx={{ fontSize: '18px'}}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontSize: '18px'}} onClick={handleClose} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default NotificationDialog;
